import React from 'react'

import ProjectItem from '../ProjectItem';

const ContainerImages = ({ handleClick, projects }) => {
    return (
        <section
            className={`flex flex-row w-screen h-screen overflow-hidden absolute z-0`}
        >
            <div className='absolute right-4 top-2 z-10 flex flex-row gap-2'>
                <a href='mailto:info@clhei.florist' className='bg-black text-white py-1 px-2'>Contact</a>
                <a href='https://blog.clhei.florist' className='bg-black text-white py-1 px-2'>Blog</a>
            </div>
            {
                projects.map((project, index) => (
                    <ProjectItem
                        key={`project-${index}`}
                        index={index}
                        project={project}
                        totalProjects={projects.length}
                        handleClick={handleClick}
                    />
                ))
            }
        </section>
    )
}

export default ContainerImages;