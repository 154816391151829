import React from 'react'
import BGBig from '../../assets/firma.png'

const ImageInit = () => {
    const [visible, setVisible] = React.useState(true)

    return (
        <>
            <section
                className='w-screen h-screen absolute top-0 right-0 overflow-hidden justify-center items-center cursor-pointer hover:animate-pulse z-10'
                style={{ backgroundColor: `#000`, display: visible? 'flex':'none', }}
                onClick={() => setVisible(false)}
            >
                <img src={BGBig} className='w-[27%] h-auto' alt="Tania Castorena"/>
            </section>
        </>
    )
}

export default ImageInit