import React from 'react'

const ModalImage = ({ project, setProject }) => {
    const keyRef = React.createRef()
    const [isShown, setIsShown] = React.useState(false)
    const [widthInfo, setWidthInfo] = React.useState(25)
    const [widthImage, setWidthImage] = React.useState(0)
    const [position, setPosition] = React.useState(0)

    const getHeight = () => {
        let htmlElement = document.documentElement, bodyElement = document.body;

        return Math.max(
            htmlElement.clientHeight, htmlElement.scrollHeight, htmlElement.offsetHeight,
            bodyElement.scrollHeight, bodyElement.offsetHeight
        )
    }

    const keyPress = (e) => {
        if( project ) {
            if (e.code === 'ArrowRight') {
                setPosition((project.images.length === position + 1) ? 0 : position + 1)
            }
            if (e.code === 'ArrowLeft') {
                setPosition((position === 0) ? project.images.length - 1 : position - 1)
            }
            if (e.code === 'Escape') {
                setProject(null);
                setPosition(0);
            }
        }
    }

    React.useEffect(() => {
        if (keyRef.current && project) {
            document.addEventListener('keydown', keyPress)
            return () => {
                document.removeEventListener('keydown', keyPress)
            }
        }
        //eslint-disable-next-line
    },[keyRef])

    React.useEffect(() => {
        if (project) {
            let _height = getHeight()

            setWidthImage(Math.round((project.images[position].width/project.images[position].height)*_height))
        }
    },[project, position])

    if(!project) return null

    const resizeTimer = (operation, width) => {
        let localControl=width;
        const resize = setInterval(() => {
            if( operation === '+' ) {
                localControl+=1
                setWidthInfo(localControl)
                if( localControl === 300 ) {
                    setIsShown(true)
                    setTimeout(() => {
                        setIsShown(false)
                        resizeTimer('-', localControl)
                    }, 6000)
                    clearInterval(resize)
                }
            } else {
                localControl-=1
                setWidthInfo(localControl)
                if( localControl === 25 ) {
                    clearInterval(resize)
                }
            }
        }, 10)
    }

    return (
        <>
            <div
                ref={keyRef}
                className={`fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center overflow-hidden`}
                style={{
                    backgroundColor: `rgba(0,0,0,0.2)`
                }}
                onClick={() => { setProject(null); setPosition(0); }}
            >
                <div
                    className={`h-full bg-top bg-no-repeat bg-cover flex flex-col items-end justify-end pb-10`}
                    style={{
                        width: `${widthImage}px`,
                        backgroundImage: `url(${project.images[position].src})`
                    }}
                    onClick={(e) => { e.stopPropagation() }}
                >
                    <div
                        className={`bg-white h-[140px] p-2 flex flex-col justify-center items-start`}
                        onMouseEnter={() => {
                            if( widthInfo === 25 )
                                resizeTimer('+', widthInfo)
                        }}
                        style={{
                            width: `${widthInfo}px`
                        }}
                    >
                        { isShown &&
                            <>
                                {
                                    project.projectClient.map(text => <span className={`tracking-widest uppercase text-[11px]`}>{text}</span>)
                                }
                                {
                                    project.projectTitle.map(text => <span className={`tracking-widest uppercase text-[11px]`}>{text}</span>)
                                }
                                {
                                    project.projectDate.map(text => <span className={`tracking-widest uppercase text-[11px]`}>{text}</span>)
                                }
                                <span className={`mt-2 uppercase text-[9px]`}>
                                    Fotografía por
                                </span>
                                {
                                    project.projectPhotography.map(text => <span className={`uppercase text-[9px]`}>{text}</span>)
                                }
                            </>
                        }
                    </div>
                    <div className={`flex flex-row mt-5 p-2 justify-center items-center h-3 gap-x-4`}>
                        {
                            project.images.map( (image, index) =>
                                <div
                                    key={`dot-image-${index}`}
                                    className={`${position===index? 'border-2 border-black': ''} w-2 h-2 bg-white rounded-full cursor-pointer`}
                                    onClick={() => {
                                        setPosition(index)
                                    }}
                                ></div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ModalImage)