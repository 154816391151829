import React from 'react'
import ImageInit from './components/image-init';
import ContainerImages from './components/ContainerIamges';
import ModalImage from './components/ModalImage';

import { projects } from './constants/data';

function App() {
    const [projectActive, setProjectActive] = React.useState(null)

    function handleClick(indexProject) {
        console.log(indexProject)
        setProjectActive(indexProject)
    }
    return (
    <>
        <ImageInit />
        <ContainerImages handleClick={handleClick} projects={projects}/>
        <ModalImage project={projectActive} setProject={setProjectActive} />
    </>
  );
}

export default App;
