//Clhei images imports
import Clhei1 from '../assets/_images/Clhei/Clhei-1.jpg'
import Clhei2 from '../assets/_images/Clhei/Clhei-2.jpg'
import Clhei3 from '../assets/_images/Clhei/Clhei-3.jpg'
import Clhei4 from '../assets/_images/Clhei/Clhei-4.jpg'
import Clhei5 from '../assets/_images/Clhei/Clhei-5.jpg'
import Clhei6 from '../assets/_images/Clhei/Clhei-6.jpg'
import Clhei7 from '../assets/_images/Clhei/Clhei-7.jpg'
import Clhei8 from '../assets/_images/Clhei/Clhei-8.jpg'

//Concierto images imports
import Concierto1 from '../assets/_images/Concierto/KevinKaarl-1.jpg'
import Concierto2 from '../assets/_images/Concierto/KevinKaarl-2.jpg'
import Concierto3 from '../assets/_images/Concierto/KevinKaarl-3.jpg'
import Concierto4 from '../assets/_images/Concierto/KevinKaarl-4.jpg'
import Concierto5 from '../assets/_images/Concierto/KevinKaarl-5.jpg'
import Concierto6 from '../assets/_images/Concierto/KevinKaarl-6.jpg'
import Concierto7 from '../assets/_images/Concierto/KevinKaarl-7.jpg'
import Concierto8 from '../assets/_images/Concierto/KevinKaarl-8.jpg'
import Concierto9 from '../assets/_images/Concierto/KevinKaarl-9.jpg'
import Concierto10 from '../assets/_images/Concierto/KevinKaarl-10.jpg'
import Concierto11 from '../assets/_images/Concierto/KevinKaarl-11.jpg'
import Concierto12 from '../assets/_images/Concierto/KevinKaarl-12.jpg'
import Concierto13 from '../assets/_images/Concierto/KevinKaarl-13.jpg'

//Cybele images imports
import Cybele1 from '../assets/_images/Cybele/CYBELE-1.jpg'
import Cybele2 from '../assets/_images/Cybele/CYBELE-2.jpg'
import Cybele3 from '../assets/_images/Cybele/CYBELE-3.jpg'
import Cybele4 from '../assets/_images/Cybele/CYBELE-4.jpg'
import Cybele5 from '../assets/_images/Cybele/CYBELE-5.jpg'
import Cybele6 from '../assets/_images/Cybele/CYBELE-6.jpg'
import Cybele7 from '../assets/_images/Cybele/CYBELE-7.jpg'
import Cybele8 from '../assets/_images/Cybele/CYBELE-8.jpg'
import Cybele9 from '../assets/_images/Cybele/CYBELE-9.jpg'
import Cybele10 from '../assets/_images/Cybele/CYBELE-10.jpg'
import Cybele11 from '../assets/_images/Cybele/CYBELE-11.jpg'
import Cybele12 from '../assets/_images/Cybele/CYBELE-12.jpg'
import Cybele13 from '../assets/_images/Cybele/CYBELE-13.jpg'
import Cybele14 from '../assets/_images/Cybele/CYBELE-14.jpg'
import Cybele15 from '../assets/_images/Cybele/CYBELE-15.jpg'

//Scarlette Gia images imports
import Scarlette1 from '../assets/_images/ScarletteGia/AndreaReyes-1.jpg'
import Scarlette2 from '../assets/_images/ScarletteGia/AndreaReyes-2.jpg'
import Scarlette3 from '../assets/_images/ScarletteGia/AndreaReyes-3.jpg'
import Scarlette4 from '../assets/_images/ScarletteGia/AndreaReyes-4.jpg'
import Scarlette5 from '../assets/_images/ScarletteGia/AndreaReyes-5.jpg'
import Scarlette6 from '../assets/_images/ScarletteGia/AndreaReyes-6.jpg'
import Scarlette7 from '../assets/_images/ScarletteGia/AndreaReyes-7.jpg'
import Scarlette8 from '../assets/_images/ScarletteGia/AndreaReyes-8.jpg'
import Scarlette9 from '../assets/_images/ScarletteGia/AndreaReyes-9.jpg'
import Scarlette10 from '../assets/_images/ScarletteGia/AndreaReyes-10.jpg'
import Scarlette11 from '../assets/_images/ScarletteGia/AndreaReyes-11.jpg'
import Scarlette12 from '../assets/_images/ScarletteGia/AndreaReyes-12.jpg'
import Scarlette13 from '../assets/_images/ScarletteGia/AndreaReyes-13.jpg'

//Polanco images imports
import Polanco1 from '../assets/_images/Polanco/0.jpg'
import Polanco2 from '../assets/_images/Polanco/1.jpg'
import Polanco3 from '../assets/_images/Polanco/2.jpg'
import Polanco4 from '../assets/_images/Polanco/3.jpg'
import Polanco5 from '../assets/_images/Polanco/4.jpg'
import Polanco6 from '../assets/_images/Polanco/5.jpg'
import Polanco7 from '../assets/_images/Polanco/6.jpg'
import Polanco8 from '../assets/_images/Polanco/7.jpg'
import Polanco9 from '../assets/_images/Polanco/8.jpg'
import Polanco10 from '../assets/_images/Polanco/9.jpg'
import Polanco11 from '../assets/_images/Polanco/10.jpg'
import Polanco12 from '../assets/_images/Polanco/11.jpg'
import Polanco13 from '../assets/_images/Polanco/12.jpg'
import Polanco14 from '../assets/_images/Polanco/13.jpg'

export const projects = [
    {
        nameProject: 'Clhei',
        images: [
            {
                src: Clhei1,
                width: 2500,
                height: 2044
            },
            {
                src:Clhei2,
                width: 2500,
                height: 1667
            },
            {
                src:Clhei3,
                width: 2501,
                height: 2501
            },
            {
                src:Clhei4,
                width: 1666,
                height: 2501
            },
            {
                src:Clhei5,
                width: 1667,
                height: 2500
            },
            {
                src:Clhei6,
                width: 2501,
                height: 2501
            },
            {
                src:Clhei7,
                width: 2400,
                height: 1406
            },
            {
                src:Clhei8,
                width: 2500,
                height: 1406
            },
        ],
        projectClient: ['Tania Castorena'],
        projectTitle: ['Inaguración Clhei'],
        projectDate: ['Octubre 2021'],
        projectPhotography: ['Onion Films'],
    },
    {
        nameProject: 'Concierto',
        images: [
            {
                src: Concierto1,
                width: 2501,
                height: 1666
            },
            {
                src: Concierto2,
                width: 2501,
                height: 1477
            },
            {
                src: Concierto3,
                width: 2501,
                height: 1666
            },
            {
                src: Concierto4,
                width: 1667,
                height: 2501
            },
            {
                src: Concierto5,
                width: 2501,
                height: 1714
            },
            {
                src: Concierto6,
                width: 2500,
                height: 1548
            },
            {
                src: Concierto7,
                width: 2501,
                height: 1666
            },
            {
                src: Concierto8,
                width: 2500,
                height: 1668
            },
            {
                src: Concierto9,
                width: 2501,
                height: 1714
            },
            {
                src: Concierto10,
                width: 2500,
                height: 1667
            },
            {
                src: Concierto11,
                width: 2501,
                height: 1613
            },
            {
                src: Concierto12,
                width: 2500,
                height: 1667
            },
            {
                src: Concierto13,
                width: 2501,
                height: 1667
            },
        ],
        projectClient: ['Sound Good Feels Good'],
        projectTitle: ['Hasta el fin del mundo Kevin Kaarl, con Pamela Morales y Sofía Campos', 'Beat 803'],
        projectDate: ['Mayo 2019'],
        projectPhotography: ['Onion Films'],
    },
    {
        nameProject: 'Cybele',
        images: [
            {
                src: Cybele1,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele2,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele3,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele4,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele5,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele6,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele7,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele8,
                width: 1666,
                height: 2501
            },
            {
                src: Cybele9,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele10,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele11,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele12,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele13,
                width: 2501,
                height: 1666
            },
            {
                src: Cybele14,
                width: 2501,
                height: 1407
            },
            {
                src: Cybele15,
                width: 2500,
                height: 1407
            },
        ],
        projectClient: ['Cybele'],
        projectTitle: [''],
        projectDate: ['Marzo 2022'],
        projectPhotography: ['Onion Films'],
    },
    {
        nameProject: 'Scarlette Gia',
        images: [
            {
                src: Scarlette1,
                width: 2502,
                height: 1584
            },
            {
                src: Scarlette2,
                width: 2502,
                height: 1668
            },
            {
                src: Scarlette3,
                width: 2500,
                height: 2053
            },
            {
                src: Scarlette4,
                width: 2500,
                height: 2011
            },
            {
                src: Scarlette5,
                width: 2429,
                height: 2500
            },
            {
                src: Scarlette6,
                width: 2499,
                height: 2123
            },
            {
                src: Scarlette7,
                width: 2502,
                height: 1601
            },
            {
                src: Scarlette8,
                width: 2502,
                height: 1621
            },
            {
                src: Scarlette9,
                width: 2502,
                height: 1710
            },
            {
                src: Scarlette10,
                width: 2501,
                height: 1805
            },
            {
                src: Scarlette11,
                width: 2501,
                height: 1882
            },
            {
                src: Scarlette12,
                width: 2501,
                height: 1759
            },
            {
                src: Scarlette13,
                width: 2501,
                height: 2135
            },
        ],
        projectClient: ['Scarlette Gia'],
        projectTitle: [''],
        projectDate: ['Abril 2022'],
        projectPhotography: ['Onion Films'],
    },
    {
        nameProject: 'FYJA 2022',
        images: [
            {
                src: Polanco1,
                width: 2500,
                height: 1667,
            },
            {
                src: Polanco2,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco3,
                width: 2500,
                height: 1666,
            },
            {
                src: Polanco4,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco5,
                width: 2501,
                height: 1666,
            },
            {
                src: Polanco6,
                width: 2501,
                height: 1666,
            },
            {
                src: Polanco7,
                width: 2502,
                height: 1667,
            },
            {
                src: Polanco8,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco9,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco10,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco11,
                width: 2501,
                height: 1666,
            },
            {
                src: Polanco12,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco13,
                width: 2500,
                height: 1668,
            },
            {
                src: Polanco14,
                width: 2501,
                height: 1666,
            }
        ],
        projectClient: ['FYJA 2002'],
        projectTitle: ['Nuevo Yo'],
        projectDate: ['Octubre 2022'],
        projectPhotography: ['Onion Films'],

    },
]