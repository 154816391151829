import React from 'react'

const ProjectItem = ({ index, project, totalProjects, handleClick }) => {
    const [positionInit, setPositionInit] = React.useState(0)

    React.useEffect(() => {
        let htmlElement = document.documentElement, bodyElement = document.body;

        let _width = Math.max(
            htmlElement.clientWidth, htmlElement.scrollWidth, htmlElement.offsetWidth,
            bodyElement.scrollWidth, bodyElement.offsetWidth
        );
        setPositionInit(index*(_width/totalProjects))
        //eslint-disable-next-line
    }, [])

    return (
        <article
            className={`h-screen absolute cursor-pointer bg-no-repeat bg-cover bg-center z-0 opacity-25 transition duration-[500ms] hover:opacity-100`}
            style={{
                width: `calc(100%/${totalProjects})`,
                backgroundImage: `url('${project.images[0].src}')`,
                left: `${positionInit}px`
            }}
            onClick={() => handleClick(project)}
        >
        </article>
    )
}

export default ProjectItem